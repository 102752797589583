import { ref } from 'vue'
import axios from 'axios'

export function useAxios () {
  const uri = ref('https://app.saborx2.cl')
  let token
  // POST Login
  const postLoginAxios = async (url = '', body = {}) => {
    try {
      const query = await axios.post(`${uri.value}${url}`, body, { headers: { 'Content-Type': 'application/json' } })
      return query
    } catch (error) { console.log('Error: ', error) }
  }
  // GET
  const getDataAxios = async (url = '', params = {}) => {
    token = await localStorage.getItem('token')
    try {
      const query = await axios.get(`${uri.value}${url}`, { params: params, headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } })
      return query
    } catch (error) { console.log('Error Get: ', error) }
  }
  // POST
  const postDataAxios = async (url = '', body = {}) => {
    token = await localStorage.getItem('token')
    try {
      const query = await axios.post(`${uri.value}${url}`, body, { headers: { 'Content-Type': 'application/json', 'auth-token': token } }) // JSON.stringify(body)
      return query
    } catch (error) { console.log('Error Post: ', error) }
  }
  // PUT
  const putDataAxios = async (url = '', body = {}) => {
    token = await localStorage.getItem('token')
    try {
      const query = await axios.put(`${uri.value}${url}`, body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } })
      return query.json()
    } catch (error) { console.log('Error Put: ', error) }
  }
  // DELETE
  const deleteDataAxios = async (url = '', body = {}) => {
    token = await localStorage.getItem('token')
    try {
      const query = await axios.delete(`${uri.value}${url}`, body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } })
      return query.json()
    } catch (error) { console.log('Error Delete: ', error) }
  }
  // RETURN
  return { postLoginAxios, getDataAxios, postDataAxios, putDataAxios, deleteDataAxios }
}
