
export function useFiltros () {
  // Computed
  const moneda = (value) => {
    const numero = parseInt(value)
    const format = numero.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
    return (numero > 999) ? `$${format}` : `$${numero}`
    /*
    const numero = parseInt(value)
    const parts = numero.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return '$' + parts.join(',')
    */
  }
  const acortar = (string, number) => {
    return string.length > number ? string.slice(0, number) + ' ...' : string
  }
  const capitalizar = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  // FORMATO DE FECHAS DE ISO a DATE ( formatea fechas de mongoDB )
  const formatoDate = (value) => {
    // const mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const mesAbr = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic']
    const momento = ['Hoy a las ', 'Ayer a las ']
    const fechaFormato = new Date(value)
    const hoymismo = new Date()
    const resta = hoymismo.getTime() - fechaFormato.getTime()
    const diasNumber = Math.round(resta / (1000 * 60 * 60 * 24))
    //
    const year = fechaFormato.getFullYear()
    const mes = fechaFormato.getMonth()
    const dia = fechaFormato.getDate()
    //
    return `${dia} ${mesAbr[mes]} ${year}` // diasNumber <= 1 ? momento[diasNumber] : `${dia} ${mesAbr[mes]} ${year}`
  }
  const formatoFecha = (value) => {
    const fechaFormato = new Date(value)
    const fecha = fechaFormato.toISOString().substring(0, 10)
    // let nuva = new Date(fecha)
    return `${fecha}`
  }
  // formatea horas de mongoDB
  const formatoHora = (value) => {
    const fechaFormato = new Date(value)
    fechaFormato.setHours(fechaFormato.getHours() - 3)
    const hora = fechaFormato.toISOString().substring(11, 16)
    if (hora !== '00:00') {
      return ` ${hora} hrs`
    } else {
      return ''
    }
  }
  const edad = (value) => {
    const fecha = new Date(value)
    const fechaHoy = new Date()
    // let nuva = new Date(fecha)
    const fecha1 = fecha.getFullYear()
    const fecha2 = fechaHoy.getFullYear()
    return `${fecha2 - fecha1}`
  }
  // Return
  return { moneda, acortar, capitalizar, formatoDate, formatoFecha, formatoHora, edad }
  /*
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
  Vue.filter('fechaStamp', value => {
    if (isNaN(value)) {
      return value
    } else if (value !== undefined) {
      // let date = JSON.stringify(value)
      // const mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      const mesAbr = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic']
      let fecha = new Date(value * 1000)
      const year = fecha.getFullYear()
      const month = String(fecha.getMonth() + 1).padStart(2, '0')
      const day = String(fecha.getDate()).padStart(2, '0')
      return `${day} ${mesAbr[month - 1]} ${year}`
    }
    return 'No date'
  })
  Vue.filter('fechaFormat', value => {
    let date = new Date(value * 1000)
    return date.toDateString()
  })
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
  // -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
  */
}
