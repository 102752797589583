<template>
  <div class="upload" :class="[ panelUpload ? 'open-panel' : '' ]">
    <div class="fondo-up"></div>
    <div class="contenido-up">
      <div class="cerrar" @click="panelUpload = false">X</div>
      <h1>{{ msg }}</h1>
      <br><br>
      <form @submit.prevent="subirFile()">
        <label>Archivo</label>
        <input type="file" ref="file" required>
        <label>Fecha <small>(no es obligatorio)</small></label>
        <input type="date" v-model="fileData.fecha">
        <label>Título <small>(no es obligatorio)</small> </label>
        <input type="text" placeholder="Título de referencia" v-model="fileData.titulo">
        <label>Descripción <small>(no es obligatorio)</small></label>
        <input type="text" placeholder="Descripción de referencia" v-model="fileData.descrip">
        <button v-if="!cargando" type="submit">SUBIR ARCHIVO</button>
        <div class="carga" v-else> <Spin /></div>
      </form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// Framework library
import { ref, inject } from 'vue'
// External library
import { useAxios } from '@/composables/useAxios'
// import Compressor from 'compressorjs'
// Components
import Spin from '@/components/Spin'
export default {
  name: 'Upload',
  components: { Spin },
  props: { msg: String },
  setup () {
    const panelUpload = inject('panelUpload')
    const fileData = ref({ titulo: '', descrip: '', fecha: '' })
    const file = ref(null)
    const cargando = ref(false)
    const { postDataAxios } = useAxios()
    // Methods
    function subirFile () {
      cargando.value = true
      const formData = new FormData()
      formData.append('titulo', fileData.value.titulo)
      formData.append('descripcion', fileData.value.descrip)
      formData.append('fecha', fileData.value.fecha)
      formData.append('_file', file.value.files[0])
      //
      postDataAxios('/api/files/upload/', formData)
        .then(response => {
          console.log(response.data)
          if (response.data.error === false) {
            panelUpload.value = false
            cargando.value = false
            fileData.value = { titulo: '', descrip: '', fecha: '' }
          }
        })
        /*
        new Compressor (fileImage.value.files[0], {
          quality: 0.6,
          maxWidth: 1024,
          maxHeight: 1024,
          success (result) {
            const formData = new FormData()
            formData.append('file', result, result.name)
            formData.append('task_id', taskDetail.value._id)
            axios.post('https://app.yobsi.cl:22443/api/task/update/image', formData, { headers: { 'auth-token': token } })
              .then((response) => { spinLoad.value = false; cerrarModal() }).catch(err => { console.log(err) })
          },
          error (err) { console.log(err.message) }
        })
        */
    }
    // Computed
    // Return
    return { panelUpload, subirFile, fileData, file, cargando }
  }
}
</script>

<style scoped>
.upload{
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  transform: translateX(100%);
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  transition: all .2s ease;
}
.fondo-up{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -2;
  box-shadow: 20px 0px 16px 16px rgba(0, 0, 0, 0.0);
  background-color: rgba(0, 0, 0, 0.0);
}
.contenido-up{
  padding: 16px;
  height: 100%;
  background-color: #fff;
  width: 30%;
  box-shadow: -20px 0px 512px 16px rgba(0, 0, 0, 0.5);
}
.contenido-up form{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.contenido-up label{
  width: 100%;
}
.contenido-up label small{
  color: rgba(255, 0, 0, 0.5);
  font-size: 12px;
}
.contenido-up input{
  width: 100%;
  padding: 8px;
  border: solid 1px #ddd;
  border-radius: 4px;
  margin-bottom: 16px;
}
.contenido-up button{
  margin-top: auto;
  cursor: pointer;
  width: 100%;
  padding: 8px;
  background-color: red;
  color: white;
  border: solid 1px red;
  border-radius: 16px;
  margin-bottom: 16px;
}
.contenido-up button:hover{
  border: solid 1px red;
  background-color: #fff;
  color: red;
}
.carga{
  width: 100%;
  text-align: center;
  color: red;
}
/* */
.cerrar{
  padding: 8px;
  width: 100%;
  text-align: right;
  cursor: pointer;
}
.open-panel{
  opacity: 1;
  transform: translateX(0%);
  pointer-events: all;
}
</style>
