import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const routes = [
  // { path: '/login', name: 'login', component: Login },
  { path: '/', name: 'Home', component: Home, meta: { privado: true } },
  { path: '/about', name: 'About', component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'), meta: { privado: true } },
  { path: '/:catchAll(.*)', name: 'login', component: Login }
]

const router = createRouter({ history: createWebHashHistory(), routes })

router.beforeEach((to, from, next) => {
  //
  const privado = to.matched.some(item => item.meta.privado)
  const token = localStorage.getItem('token')
  privado && token === null ? next('/login') : next()
  /*
  if (privado && token === null) {
    next('/login')
  } else {
    next()
  }
  */
  //
  const idnivel = localStorage.getItem('user')
  if (privado && idnivel.nivel === 12 && token === null) {
    next('/login')
  } else {
    next()
  }
  //
})

export default router
