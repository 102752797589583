<template>
  <!--
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>
-->
  <router-view/>
</template>

<style>
@import url('./assets/spinloader.css');
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
body{
  background-color: #fff;
}
a{
  color: #FE2E2E;
  text-decoration: none;
}
a:hover{
  text-decoration: underline;
}
::placeholder{
  color: #999;
}
/* */
#app {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #fff;
}

#nav a.router-link-exact-active {
  color: red;
}
</style>
