<template>
  <Upload msg="Subir Archivo"/>
  <div class="home">
    <div class="nav">
      <div class="logo"><img alt="Saborx2" src="../assets/Logo.png"></div>
      <div class="">Bienvenido Admin</div>
      <div class="logout" @click="salirApp()">Log out</div>
    </div>
    <!-- -->
    <div class="contenido">
      <div class="filtros">
        <h3>Buscar</h3>
        <input type="text" v-model="filtroName" placeholder="Buscar por título" @input="filtroPorNombre()">
        <h3>Año</h3>
        <select v-model="searchanio" @change="filtroPorAnio()">
          <option selected disabled value=''>Seleccionar</option>
          <option value="2017">2017</option>
          <option value="2018">2018</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select>
        <h3>Mes</h3>
        <select v-model="searchMes" @change="filtroPorMes()">
          <option selected disabled value=''>Seleccionar</option>
          <option value="-01-">Enero</option>
          <option value="-02-">Febrero</option>
          <option value="-03-">Marzo</option>
          <option value="-04-">Abril</option>
          <option value="-05-">Mayo</option>
          <option value="-06-">Junio</option>
          <option value="-07-">Julio</option>
          <option value="-08-">Agosto</option>
          <option value="-09-">Septiembre</option>
          <option value="-10-">Octubre</option>
          <option value="-11-">Noviembre</option>
          <option value="-12-">Diciembre</option>
        </select>
        <br><br>
        <button class="reset" type="button" @click="resetFiltros()">Reset</button>
        <!-- -->
      </div>
      <!-- -->
      <div class="tabla-data">
        <div class="acciones">
          <div class="upload" @click="panelUpload = true">Subir archivos</div>
        </div>
        <h2>Lista de archivos Subidos</h2>
        <table>
          <thead>
            <tr>
                <th>Fecha</th>
                <th>Info</th>
                <th>File</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in archivosFiltrados" :key="index">
              <td>
                <div class="fechas">
                  <div class="dia">{{ formatoFecha(item.fecha).split('-')[2] }}</div>
                  <div class="mes">{{ mes[parseInt(formatoFecha(item.fecha).split('-')[1])] }}</div>
                  <div class="anio">{{ formatoFecha(item.fecha).split('-')[0] }}</div>
                </div>
              </td>
              <td>{{ item.titulo === '' ? 'Sin título' : item.titulo }} <br> <small>{{ item.info === '' ? 'Archivo sin descripción' : item.info }}</small></td>
              <td>
                <a :href="`https://app.saborx2.cl/archivos/${item.file}`" target="_blank">
                {{ item.file }}
              </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// Framework library
import { ref, computed, provide, watchEffect } from 'vue'
// External library
import { useAxios } from '@/composables/useAxios'
import { useFiltros } from '@/composables/useFiltros'
// Components
import Upload from '@/components/upload'
export default {
  name: 'Home',
  components: { Upload },
  setup () {
    // Data
    const mes = ref(['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ])
    //
    const archivos = ref()
    const archivosFiltrados = ref()
    const { getDataAxios } = useAxios()
    const { formatoFecha } = useFiltros()
    const panelUpload = ref(false)
    provide('panelUpload', panelUpload)
    // data filtros
    const filtroName = ref('')
    const searchanio = ref('')
    const searchMes = ref('')
    // Methods
    function getFile () {
      getDataAxios('/api/files/get/', null).then(response => {
        archivos.value = response.data.data
        archivosFiltrados.value = response.data.data
        sortByFecha()
      })
    }

    function sortByFecha () {
      archivosFiltrados.value.sort(function(a,b){
        return new Date(b.fecha) - new Date(a.fecha);
      })
    }

    function salirApp () {
      localStorage.clear()
      window.location.href = '.#/'
    }

    function filtroPorNombre () {
      if (filtroName.value.length > 2) {
        archivosFiltrados.value = []
        archivosFiltrados.value = archivos.value.filter(item => item.titulo.toLowerCase().match(filtroName.value) )
      } else {
        archivosFiltrados.value = archivos.value
      }
      sortByFecha()
    }

    function filtroPorAnio () {
      archivosFiltrados.value = []
      archivosFiltrados.value = archivos.value.filter(item => item.fecha.match(searchanio.value) )
      sortByFecha()
    }

    function filtroPorMes () {
      archivosFiltrados.value = []
      archivosFiltrados.value = archivos.value.filter(item => item.fecha.match(searchMes.value) )
      sortByFecha()
    }
    function resetFiltros () {
      searchanio.value = ''
      searchMes.value = ''
      filtroName.value = ''
      archivosFiltrados.value = archivos.value
      sortByFecha()
    }
    // Computed
    // watchEffect
    watchEffect(() => {
      if (panelUpload.value === false) {
        getFile()
      }
    })
    // Return
    return {
      archivosFiltrados,
      getFile,
      salirApp,
      formatoFecha,
      panelUpload,
      mes,
      searchanio,
      searchMes,
      filtroPorAnio,
      filtroPorMes,
      filtroPorNombre,
      filtroName,
      resetFiltros
    }
  }
}
</script>

<style scoped>
.fechas{
  padding: 16px 8px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  box-shadow: 0px 2px 8px -4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.fechas .dia{
  font-size: 32px;
  font-weight: bold;
}
.fechas .mes{
  font-size: 24px;
  font-weight: bold;
  color: red;
}
/* */
.nav{
  padding: 8px 6%;
  width: 100%;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.nav .logo img{
  height: 50px;
}
.nav .logout{
  padding: 8px 16px;
  cursor: pointer;
  background-color: rgba(255, 0, 0, 1);
  border-radius: 4px;
}
.nav .logout:hover{
  background-color: rgba(255, 0, 0, 0.5);
}
/* */
.contenido{
  width: 100%;
  padding: 1% 1%;
  display: flex;
}
.contenido .filtros{
  padding: 16px;
  margin-right: 16px;
  width: 20%;
  border-right: solid 1px rgba(255, 0, 0, 0.5);
}
.contenido .filtros h3{
  margin-top: 16px;
  margin-bottom: 8px;
}
.contenido .filtros input, .contenido .filtros select{
  width: 100%;
  padding: 4px;
  border: solid 1px #ddd;
  border-radius: 4px;
}
/* */
/* */
.contenido .acciones{
  width: 100%;
  padding: 16px;
  border-bottom: solid 1px #f1f1f1;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}
.contenido .acciones .upload{
  padding: 8px 16px;
  cursor: pointer;
  background-color: rgba(255, 0, 0, 1);
  border-radius: 32px;
  color: white;
  font-size: 14px;
}
/* */
.contenido .tabla-data{
  width: 80%;
}
.contenido .tabla-data table{
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.contenido .tabla-data table tbody tr:hover{
}
.contenido .tabla-data table th{
  border: none;
  padding: 2% 0% !important;
  border-bottom: solid 1px #ddd;
}
.contenido .tabla-data table td{
  border: none;
  padding: 8px 0% !important;
  border-bottom: solid 1px #ddd;
}
.contenido .tabla-data table td small{
  color: #888;
}

.reset{
  border: none;
  background-color: red;
  width: 100%;
  padding: 8px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
</style>
