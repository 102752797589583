<template>
  <div class="fondo"></div>
  <div class="login">
    <div class="formulario">
      <img alt="Saborx2" src="../assets/Logo.png">
      <form @submit.prevent="loginUser()">
        <h2>Login FileUp</h2>
        <br><br>
        <label>Email</label>
        <input type="email" placeholder="Email" v-model="userLogin.email">
        <label>Contraseña</label>
        <input type="password" placeholder="Contraseña" v-model="userLogin.password">
        <button type="submit" :disabled="!validateLogin"> {{ !validateLogin ? 'Debes ingresar tu usuario y contraseña' : 'Login' }}</button>
      </form>
      <div class="dev">Desarrollado por <a href="">Canguro Digital</a></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// Framework library
import { computed, ref } from 'vue'
// External library
import { useAxios } from '@/composables/useAxios'
// Components
export default {
  name: 'Login',
  components: {},
  setup () {
    // Data
    const userLogin = ref({ email: '', password: '' })
    const { postLoginAxios } = useAxios()
    // Methods
    function loginUser () {
      const formData = new FormData()
      formData.append('email', userLogin.value.email)
      formData.append('password', userLogin.value.password)
      //
      postLoginAxios('/api/user/login/', formData)
        .then(response => { console.log(response.data); localStorage.setItem('token', response.data.token); window.location.href = '.#/' })
    }
    // Computed
    const validateLogin = computed(() => {
      return userLogin.value.email !== '' && userLogin.value.password !== '' ? true : false
    })
    // Return
    return { userLogin, validateLogin, loginUser }
  }
}
</script>

<style scoped>
.login{
  color: white;
}
.fondo{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -10;
  top: 0;
  left: 0;
  background-color: #000;
}
.formulario{
  height: 90vh;
  padding: .5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.formulario form{
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.formulario form label{
  color: #FE2E2E;
  text-align: left;
}
.formulario form button:disabled{
  background-color: #ddd;
  color: #666 !important;
  border: solid 1px #666;
}
.formulario form input, .formulario form button{
  margin-bottom: 16px;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
}
.formulario form button{
  cursor: pointer;
  background-color: #FE2E2E;
  color: white;
}
.formulario form button:hover{
  background-color: #FE4E4E;
}
.dev{
  font-size: 11px;
  margin-top: auto;
}

/* @media */
@media only screen and (min-width: 601px) {
  .formulario form{
    width: 60%;
  }
}

@media only screen and (min-width: 993px) {
  .formulario form{
    width: 20%;
  }
}
</style>
